// MARK: used in v2
import { Cart, Platform } from "@yoco/shop-sdk/lib/types"
import { useCallback, useContext, useEffect, useState } from "react"

import { REASON_NOT_AVAILABLE_MAPPING, DEFAULT_STOREFRONT } from "./constants"
import { CartContext } from "./store"
import {
  isPurchasableBundle,
  isPurchasableProduct,
  PurchasableItem,
  SelectedPurchasableItem,
} from "../hooks/V2/storyblok"
import { NotificationContext } from "../notifications/context"
import { getCookie, isMobileDevice } from "../utils/analytics"
import {
  StoredBundle,
  StoredItemType,
  StoredProduct,
  consolidateBundle,
  consolidateProduct,
  fetchCartId,
  fetchCartProducts,
  isConsolidatedBundle,
  isConsolidatedProduct,
  isStoredBundle,
  isStoredProduct,
  itemAlreadyInCart,
  shop,
  storeCartId,
  storeCartProducts,
} from "../utils/shop"

import { useGetBundleByCode } from "@hooks/bundles"
import { useGetProductBySku } from "@hooks/products"
import { useGetStoryblokBundle } from "@hooks/V2/storyblok/useBundle"
import { useGetStoryblokProduct } from "@hooks/V2/storyblok/useProduct"

export const useFetchConsolidatedCartProducts = (): (() => (
  | YocoCom.ConsolidatedBundle
  | YocoCom.ConsolidatedProduct
)[]) => {
  const products = fetchCartProducts()
  const getStoryblokProduct = useGetStoryblokProduct()
  const getStoryblokBundle = useGetStoryblokBundle()
  const getProductBySku = useGetProductBySku()
  const getBundleByCode = useGetBundleByCode()
  const removeProduct = useRemoveProduct()
  const removeBundle = useRemoveBundle()
  const fetchConsolidatedCartProducts = useCallback(() => {
    const consolidatedProducts: YocoCom.ConsolidatedCartItem[] = []

    if (products && products.length > 0) {
      products.forEach((product) => {
        if (isStoredProduct(product)) {
          const storyblokProduct = getStoryblokProduct(product.id)
          const sdkProduct = getProductBySku(product.sku)

          //Product no longer exists on storyblok or on API. usually due to SKU change
          if (!storyblokProduct || !sdkProduct) {
            removeProduct(product.sku)
            return null
          }

          return consolidatedProducts.push(
            consolidateProduct(
              product.id,
              product.quantity,
              sdkProduct,
              storyblokProduct
            )
          )
        } else {
          const storyblokBundle = getStoryblokBundle(product.id)
          const sdkBundle = getBundleByCode(product.bundleCode)

          //Bundle no longer exists on storyblok or on API. usually due to SKU change
          if (!storyblokBundle || !sdkBundle) {
            removeBundle(product.bundleCode)
            return null
          }
          return consolidatedProducts.push(
            consolidateBundle(
              product.id,
              product.quantity,
              sdkBundle,
              storyblokBundle
            )
          )
        }
      })
    }

    return consolidatedProducts
  }, [
    products,
    getStoryblokProduct,
    getProductBySku,
    getStoryblokBundle,
    getBundleByCode,
    removeBundle,
    removeProduct,
  ])

  return fetchConsolidatedCartProducts
}

export const useRemoveProduct = (): ((sku: string) => Promise<void>) => {
  const cartContext = useContext(CartContext)
  const getProduct = useGetProductBySku()
  const cartId = fetchCartId()

  const removeProduct = useCallback(
    async (sku: string) => {
      const product = getProduct(sku)

      if (!cartId || !product)
        return console.error(`Missing cartId or product sku(${sku})`)

      const products = fetchCartProducts() ?? []

      storeCartProducts(
        products.filter((product) => {
          if (isStoredProduct(product)) {
            return product.sku !== sku
          } else {
            return true
          }
        })
      )

      cartContext.setProducts(
        cartContext.products.filter((product) => {
          if (isStoredProduct(product)) {
            return product.sku !== sku
          } else {
            return true
          }
        })
      )
    },
    [cartId, getProduct, cartContext]
  )

  return removeProduct
}

export const useRemoveBundle = (): ((bundleCode: string) => Promise<void>) => {
  const cartContext = useContext(CartContext)
  const getBundle = useGetBundleByCode()
  const cartId = fetchCartId()

  const removeBundle = useCallback(
    async (bundleCode: string) => {
      const bundle = getBundle(bundleCode)

      if (!cartId || !bundle)
        return console.error(`Missing cartId or bundle code(${bundleCode})`)

      const products = fetchCartProducts() ?? []

      storeCartProducts(
        products.filter((product) => {
          if (isStoredBundle(product)) {
            return product.bundleCode !== bundleCode
          } else {
            return true
          }
        })
      )

      cartContext.setProducts(
        cartContext.products.filter((product) => {
          if (isConsolidatedBundle(product)) {
            return product.bundleCode !== bundleCode
          } else {
            return true
          }
        })
      )
    },
    [cartId, getBundle, cartContext]
  )

  return removeBundle
}

export const useInitCart = (): (() => Promise<Cart | null>) => {
  const cartContext = useContext(CartContext)
  const notificationContext = useContext(NotificationContext)

  const initCart = async () => {
    let cartId = fetchCartId()
    let cart: Cart | null = null

    if (!cartId) {
      try {
        const whoAreYou = getCookie("wau")
        cart = await shop.carts.create({
          source: "shop",
          tracking_attribution: {
            platform: isMobileDevice() ? Platform.MOBILE_WEB : Platform.WEB,
            wau: whoAreYou || "",
          },
        })
        cartId = cart.id
        cartContext.setCartId(cart.id)
        storeCartId(cartId)
      } catch (error: any) {
        notificationContext.setNotification({
          type: "danger",
          title: "Request failed",
          message: error?.message,
        })
      }
    } else {
      cart = await shop.carts.get(cartId)
    }

    return cart
  }

  return initCart
}

export const useAddProduct = () => {
  const initCart = useInitCart()
  const getProduct = useGetProductBySku()
  const getStoryblokProduct = useGetStoryblokProduct()

  const addProduct = useCallback(
    async (id: string, sku: string, quantity: number) => {
      const sdkProduct = getProduct(sku)
      const storyblokProduct = getStoryblokProduct(id)
      const cart = await initCart()
      if (!cart) return console.error("Cart failed to initialise")
      if (!sdkProduct || !storyblokProduct) {
        return console.error(`Failed to add product. Invalid sku: ${sku}`)
      }

      const consolidatedProduct = consolidateProduct(
        id,
        quantity,
        sdkProduct,
        storyblokProduct
      )

      return consolidatedProduct
    },
    [getProduct, getStoryblokProduct, initCart]
  )

  return addProduct
}

export const useAddToCart = (): ((
  id: string,
  sku: string,
  quantity: number
) => Promise<void>) => {
  const addProduct = useAddProduct()
  const cartContext = useContext(CartContext)

  const addToCart = useCallback(
    async (id: string, sku: string, quantity: number) => {
      const consolidatedProduct = await addProduct(id, sku, quantity)

      if (consolidatedProduct) {
        const products = fetchCartProducts() ?? []

        const storedProducts = [
          ...products,
          { id, sku, quantity, type: "product" as StoredItemType },
        ]

        cartContext.setProducts([...cartContext.products, consolidatedProduct])

        storeCartProducts(storedProducts)
      }
    },
    [addProduct, cartContext]
  )

  return addToCart
}

const useAddBundle = () => {
  const initCart = useInitCart()
  const getBundle = useGetBundleByCode()
  const getStoryblokBundle = useGetStoryblokBundle()

  const addBundle = useCallback(
    async (id: string, code: string, quantity: number) => {
      const sdkBundle = getBundle(code)
      const storyBlokBundle = getStoryblokBundle(id)
      const cart = await initCart()
      if (!cart) return console.error("Cart failed to initialise")
      if (!sdkBundle || !storyBlokBundle) {
        return console.error(`Failed to add bundle. Invalid bundle: ${code}`)
      }

      const consolidatedBundle = consolidateBundle(
        id,
        quantity,
        sdkBundle,
        storyBlokBundle
      )

      return consolidatedBundle
    },
    [getBundle, getStoryblokBundle, initCart]
  )

  return addBundle
}

export const useAddBundleToCart = (): ((
  id: string,
  code: string,
  quantity: number
) => Promise<void>) => {
  const addBundle = useAddBundle()
  const cartContext = useContext(CartContext)

  const addBundleToCart = useCallback(
    async (id: string, code: string, quantity: number) => {
      const consolidatedBundle = await addBundle(id, code, quantity)

      if (consolidatedBundle) {
        const products = fetchCartProducts() ?? []

        const storedProducts = [
          ...products,
          {
            id,
            bundleCode: code,
            quantity,
            type: "bundle" as StoredItemType,
          },
        ]

        cartContext.setProducts([...cartContext.products, consolidatedBundle])

        storeCartProducts(storedProducts)
      }
    },
    [addBundle, cartContext]
  )

  return addBundleToCart
}

/**
 * Bulk add/update items in the cart.
 *
 * If the item already exists in the cart,
 * this will increment the quantity of the cart
 * item by the quantity of SelectedPurchasableItem
 *
 * Returns an array of the items both
 * updated and added to the cart with
 * the quantities they were added with.
 *
 * @todo look into consolidating other hooks to use this hook
 */
export const useAddBulkToCart = () => {
  const cartContext = useContext(CartContext)
  const addProduct = useAddProduct()
  const addBundle = useAddBundle()

  const addBulkToCart = useCallback(
    async (selectedPurchasableItems: SelectedPurchasableItem[]) => {
      const existingCartItems = cartContext.products
      const newCartItems: YocoCom.ConsolidatedCartItem[] = []
      const updatedCartItems: YocoCom.ConsolidatedCartItem[] = []

      const existingStoredItems = fetchCartProducts() ?? []
      const storedItems: (StoredBundle | StoredProduct)[] = []

      for (const { purchasableItem, quantity } of selectedPurchasableItems) {
        const alreadyInCart = itemAlreadyInCart(purchasableItem)

        if (alreadyInCart) {
          const existingCartItemIndex = isPurchasableProduct(purchasableItem)
            ? existingCartItems.findIndex((cartItem) =>
                isConsolidatedProduct(cartItem)
                  ? cartItem.sku === purchasableItem.sku
                  : false
              )
            : existingCartItems.findIndex((cartItem) =>
                isConsolidatedBundle(cartItem)
                  ? cartItem.bundleCode === purchasableItem.bundleCode
                  : false
              )

          const newQuantity =
            existingCartItems[existingCartItemIndex].quantity + quantity

          if (existingCartItemIndex !== -1) {
            existingCartItems[existingCartItemIndex].quantity = newQuantity
            updatedCartItems.push({
              ...existingCartItems[existingCartItemIndex],
              // this is the quantity added to the cart instead of the total quantity
              quantity,
            })
          }

          const existingStoredItemIndex = existingStoredItems.findIndex(
            (item) => item.id === purchasableItem.id
          )

          if (existingStoredItemIndex !== -1) {
            existingStoredItems[existingStoredItemIndex].quantity = newQuantity
          }

          continue
        }

        if (isPurchasableProduct(purchasableItem)) {
          const consolidatedProduct = await addProduct(
            purchasableItem.id,
            purchasableItem.sku,
            quantity
          )
          if (consolidatedProduct) {
            newCartItems.push(consolidatedProduct)
            storedItems.push({
              id: purchasableItem.id,
              sku: purchasableItem.sku,
              quantity,
              type: "product",
            })
          }
        }

        if (isPurchasableBundle(purchasableItem)) {
          const consolidatedBundle = await addBundle(
            purchasableItem.id,
            purchasableItem.bundleCode,
            quantity
          )
          if (consolidatedBundle) {
            newCartItems.push(consolidatedBundle)
            storedItems.push({
              id: purchasableItem.id,
              bundleCode: purchasableItem.bundleCode,
              quantity,
              type: "bundle",
            })
          }
        }
      }

      cartContext.setProducts([...existingCartItems, ...newCartItems])

      storeCartProducts([...existingStoredItems, ...storedItems])

      return [...newCartItems, ...updatedCartItems]
    },
    [addBundle, addProduct, cartContext]
  )

  return addBulkToCart
}

export const useGetCartQuantity = (): number => {
  const cartContext = useContext(CartContext)
  return (cartContext.products as YocoCom.ConsolidatedCartItemBase[]).reduce(
    (
      accumulatedProducts: number,
      product: YocoCom.ConsolidatedCartItemBase
    ) => {
      return accumulatedProducts + product.quantity
    },
    0
  )
}

//TODO: update this to be a single updateHook that handles the typeguard check and combines the two item type hooks
export const useUpdateProductCartQuantity = () => {
  const cartContext = useContext(CartContext)
  const updateQuantity = useCallback(
    (productSku: string, quantity: number) => {
      const updatedProducts: (
        | YocoCom.ConsolidatedBundle
        | YocoCom.ConsolidatedProduct
      )[] = cartContext.products.map((contextProduct) => {
        if (
          isConsolidatedProduct(contextProduct) &&
          contextProduct.sku === productSku
        )
          return { ...contextProduct, quantity: quantity }

        return contextProduct
      })

      const reducedProducts = updatedProducts.map((product) => {
        if (isConsolidatedProduct(product)) {
          return {
            id: product.id,
            sku: product.sku,
            quantity: product.quantity,
            type: "product" as StoredItemType,
          }
        } else {
          return {
            id: product.id,
            bundleCode: product.bundleCode,
            quantity: product.quantity,
            type: "bundle" as StoredItemType,
          }
        }
      })

      // NOTE: typescript doesnt restrict the extraProperties of updatedProducts, so explicitedly reducing it to reducedProducts
      // https://github.com/microsoft/TypeScript/issues/12936

      storeCartProducts(reducedProducts)
      cartContext.setProducts([...updatedProducts])
    },
    [cartContext]
  )

  return updateQuantity
}

export const useUpdateBundleCartQuantity = () => {
  const cartContext = useContext(CartContext)
  const updateBundleQuantity = useCallback(
    (bundleCode: string, quantity: number) => {
      const updatedProducts: (
        | YocoCom.ConsolidatedBundle
        | YocoCom.ConsolidatedProduct
      )[] = cartContext.products.map((contextProduct) => {
        if (
          isConsolidatedBundle(contextProduct) &&
          contextProduct.bundleCode === bundleCode
        )
          return { ...contextProduct, quantity: quantity }

        return contextProduct
      })

      const reducedProducts = updatedProducts.map((product) => {
        if (isConsolidatedProduct(product)) {
          return {
            id: product.id,
            sku: product.sku,
            quantity: product.quantity,
            type: "product" as StoredItemType,
          }
        } else {
          return {
            id: product.id,
            bundleCode: product.bundleCode,
            quantity: product.quantity,
            type: "bundle" as StoredItemType,
          }
        }
      })

      // NOTE: typescript doesnt restrict the extraProperties of updatedProducts, so explicitedly reducing it to reducedProducts
      // https://github.com/microsoft/TypeScript/issues/12936

      storeCartProducts(reducedProducts)
      cartContext.setProducts([...updatedProducts])
    },
    [cartContext]
  )

  return updateBundleQuantity
}

export const useClearUnavailableProductsEffect = (): boolean => {
  const [completed, setCompleted] = useState(false)
  const removeProduct = useRemoveProduct()
  const removeBundle = useRemoveBundle()
  const cartContext = useContext(CartContext)
  const notificationContext = useContext(NotificationContext)
  const checkAndHandleUnavailableProducts = useCallback(async () => {
    const shopProducts = await shop.products.list()
    const shopBundles = await shop.bundles.list() //get bundles with new call

    const removedProducts = []
    const removedBundles = []

    for (const cartItem of cartContext.products) {
      if (isConsolidatedProduct(cartItem)) {
        const shopProduct = shopProducts.find(
          (shopProduct) =>
            shopProduct.sku === cartItem.sku &&
            // TODO: remove this when product type definition has been updated in shop-sdk
            // @ts-ignore
            shopProduct.storefronts.includes(DEFAULT_STOREFRONT),
          []
        )

        //Product no longer exists on API. usually due to SKU change
        // Or no longer marked as purchasable
        if (!shopProduct || !shopProduct?.can_be_purchased) {
          removedProducts.push({
            name: cartItem.name,
            reason: "Product no longer purchasable",
          })
          removeProduct(cartItem.sku)
        } else if (shopProduct?.reason_not_available) {
          removedProducts.push({
            name: cartItem.name,
            reason:
              REASON_NOT_AVAILABLE_MAPPING[shopProduct.reason_not_available],
          })
          removeProduct(cartItem.sku)
        }
      } else {
        const shopBundle = shopBundles.find(
          (shopBundle) =>
            shopBundle.bundle_code === cartItem.bundleCode &&
            // TODO: remove this when product type definition has been updated in shop-sdk
            // @ts-ignore
            shopBundle.storefronts.includes(DEFAULT_STOREFRONT),
          []
        )
        //Bundle no longer exists on API. usually due to bundleCode change
        // Or no longer marked as purchasable
        if (!shopBundle || !shopBundle?.can_be_purchased) {
          removedBundles.push({
            name: cartItem.name,
            reason: "Bundle no longer purchasable",
          })
          removeBundle(cartItem.bundleCode)
        } else if (shopBundle?.reason_not_available) {
          removedBundles.push({
            name: cartItem.name,
            reason:
              REASON_NOT_AVAILABLE_MAPPING[shopBundle.reason_not_available],
          })
          removeBundle(cartItem.bundleCode)
        }
      }
    }

    if (removedProducts.length || removedBundles.length) {
      //TODO update to include removedBundles
      notificationContext.setNotification({
        type: "warning",
        message: `${removedProducts.reduce(
          (previousValue, currentItem, currentIndex) =>
            `${previousValue}${currentIndex !== 0 ? " | " : ""}${
              currentItem.name
            } (${currentItem.reason})`,
          ""
        )}`,
        title: "Product not available",
      })
    }
  }, [cartContext.products, notificationContext, removeProduct, removeBundle])

  useEffect(() => {
    if (cartContext.initialised && !completed) {
      checkAndHandleUnavailableProducts()

      setCompleted(true)
    }
  }, [
    cartContext.initialised,
    checkAndHandleUnavailableProducts,
    completed,
    removeProduct,
  ])

  return completed
}

export const useGetCartItem = () => {
  const cartContext = useContext(CartContext)

  /**
   * Find an existing cart item.
   *
   * Use this when `itemAlreadyInCart` isnt enough
   *
   */
  const getCartItem = (item?: PurchasableItem) => {
    if (!item || !cartContext) {
      return
    }

    if (isPurchasableProduct(item)) {
      return cartContext.products.find((product) =>
        isConsolidatedProduct(product) ? product.sku === item.sku : false
      )
    }

    if (isPurchasableBundle(item)) {
      return cartContext.products.find((product) =>
        isConsolidatedBundle(product)
          ? product.bundleCode === item.bundleCode
          : false
      )
    }
  }

  return getCartItem
}

export const useGetContextProduct = (purchasableItem?: PurchasableItem) => {
  const cartContext = useContext(CartContext)
  if (!purchasableItem) return
  const contextProduct = cartContext.products.find((currentProduct) =>
    isPurchasableBundle(purchasableItem)
      ? isConsolidatedBundle(currentProduct) &&
        currentProduct.bundleCode === purchasableItem.bundleCode
      : isConsolidatedProduct(currentProduct) &&
        currentProduct.sku === purchasableItem.sku
  )
  return contextProduct
}
