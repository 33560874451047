import classNames from "classnames"
import { navigate } from "gatsby"
import React from "react"

import TextButton from "../components/elements/V2/Buttons/TextButton"
import GatsbyStoryblokImage from "../components/elements/V2/GatsbyStoryblokImage"
import CloseIcon from "../components/elements/V2/Icons/CloseIcon"
import { Color } from "../constants/V2/color"

import Typography, { TypographySize } from "@components/elements/V2/Typography"
import { withPrefix } from "@utils/url"
import { getBackgroundColorClass, getAccentColor } from "@utils/V2/color"

export interface ToastProps {
  icon?: string | React.ReactNode
  iconAlt?: string
  text: string
  typographySize?: TypographySize
  variant?: "info" | "alert" | "success" | "warning" | "info-dark"
  linkUrl?: string
  linkColor?: Color
  linkText?: string
  trackingOptions?: TrackingOptions
  className?: string
  onClick?: VoidFunction
}

interface TrackingOptions {
  name: string
  key: string
  value: string
}

const Toast = ({
  icon,
  iconAlt = "",
  variant = "info",
  text,
  typographySize = "body-md",
  linkUrl,
  linkColor = Color.Blue,
  linkText,
  trackingOptions,
  className,
  onClick,
}: ToastProps) => {
  const backgroundColor =
    variant === "success"
      ? Color.Green
      : variant === "alert"
        ? Color.Pink
        : variant === "warning"
          ? Color.Yellow
          : variant === "info-dark"
            ? Color.Blue
            : Color.LightBlue

  const textColor =
    variant === "alert" ? Color.Orange : getAccentColor(backgroundColor)

  return (
    <div
      className={classNames(
        "flex min-h-56 items-start space-x-[8px] rounded-[10px] px-16 py-16 sm:flex-row sm:items-center lg:space-x-[10px] lg:px-20",
        getBackgroundColorClass(backgroundColor),
        className
      )}
    >
      <div className="flex items-start gap-8">
        {icon &&
          (typeof icon === "string" ? (
            <GatsbyStoryblokImage
              image={icon}
              alt={iconAlt || "Toast Icon"}
              className="h-20 w-20 md:h-24 md:w-24"
            />
          ) : (
            <div className="h-20 w-20 md:h-24 md:w-24">{icon}</div>
          ))}

        <div className="inline-block md:pt-[2px]">
          <Typography
            weight="book"
            font="grotesk"
            size={typographySize}
            className="mr-4 inline"
            color={textColor}
            text={text}
          />
          {linkText && (
            <TextButton
              style="text"
              weight="medium"
              uppercase={false}
              color={linkColor || textColor}
              className="inline !gap-0 !py-[3px] after:!h-0"
              textSize={typographySize}
              text={linkText}
              trackingEvent={trackingOptions ? trackingOptions.name : undefined}
              trackingEventKey={
                trackingOptions ? trackingOptions.key : undefined
              }
              trackingEventValue={
                trackingOptions ? trackingOptions.value : undefined
              }
              onClick={() => {
                if (linkUrl) navigate(withPrefix(linkUrl))
              }}
            />
          )}
        </div>
      </div>

      {onClick && (
        <div className="h-24 w-24 cursor-pointer">
          <CloseIcon
            width="24px"
            height="24px"
            hideBorder
            strokeWidth="2"
            onClick={onClick}
            strokeColor={textColor}
          />
        </div>
      )}
    </div>
  )
}

export default Toast
